export const m =
{
lqlj: "確定領取",
sqinfo: "要使用您個人賬戶的功能，請使用錢包授權",
sqbtn: "錢包授權",
sqload: "授權中...",
dltt: "登錄到您的賬戶",
dlinfo: "要使用您個人賬戶的功能，請點擊鏈接錢包",
dlbtn: "鏈接錢包",
bdtt: "綁定上級",
bdinfo: "請輸入推薦人錢包地址",
bdbtn: "確認綁定",
bdload: "綁定中...",
lqsg: "領取成功",
lqsb: "領取失敗",
day: "天",
shi:"時",
fen:"分",
miao:"秒",
fzcg:"複製成功",
fzsb:"複製失敗",
yhdj:"用戶等級",

notice:"ARC是全球首個基於智算集成服務網絡建設的Web3智算金融平臺，還是全球首個實現無干擾、自動化運行的金融服務平臺。",
cyarc:"參與ARC",
ljyy:"立即預約",
yysl:"預約數量",
xzsl:"選擇數量",
qr:"確認",
wdldx:"我的流動性",
wdlp:"我的LP值",
wdqydd:"我的契約訂單",
jrgtxq:"加入ARC，共同掀起DeFi新浪潮!",
qyc:"契約池",
cbdz:"持幣地址",
wdqy:"我的契約",
qysy:"契約收益",
qrlq:"確認領取",
lq:"領取",
mx:"明細",
dqjl:"社區獎勵",
ny:"您有",
wcqbqr:"完成錢包確認",
zyyyyd:"注意：24小時內只能預約一筆訂單",
dqsl:"授權數量",
yycg:"預約成功",
yysb:"預約失敗",
mysj:"沒有數據",
sqjd:"社區節點",
gsjd:"共識節點",
dqjx:"社區競選",
gsjdzsl:"共識節點總數量",
gsjdydcsl:"共識節點已達成數量",
dqgsjdjc:"當前共識節點獎池",
wdjdjc:"我的節點獎池",
wdcjd:"未達成節點",
ydcjd:"已達成節點",
djzlmm:"節點總量51個，當小區業績達到2w美金按照順序成爲節點。",
jdmx:"節點明細",
sqjxjc:"社區競選獎池",
wdsqjxjc:"我的社區競選獎池",
xqyjmm:"小區業績達到1.5萬美金，排名前51名可獲得競選池獎勵。",
sqpm:"社區排名",
pm:"排名",
xqyj:"小區業績",
zyj:"總業績",
dq:"社區",
wdyqlj:"我的邀請鏈接",
fz:"複製",
qysqtj:"契約社區統計",
zjtjhy:"直接推薦會員",
xqyjzje:"小區業績總金額",
sqyjzje:"社區業績總金額",
wdfxmx:"我的分享明細",
wx:"無效",
wdyydd:"我的預約訂單",
ywcdqydd:"已完成的契約訂單",
dzf:"待支付",
yyz:"預約中",
dyz:"待質押",
qyje:"契約金額",
yydjs:"預約倒計時",
zydjs:"質押倒計時",
zfdjs:"支付倒計時",
wcqydd:"完成契約訂單",
ywc:"已完成",
ysh:"已贖回",
zysl:"質押數量",
jydjs:"解押倒計時",
shqydd:"贖回契約訂單",
wcqyddxyzf:"完成預約訂單需要支付",
shcg:"贖回成功",
shsb:"贖回失敗",
zfcg:"支付成功",
zfsb:"支付失敗",
wdfx:"我的分享",
yxyh:"有效用戶",
wxyh:"無效用戶",
grtr:"個人投入",
sqyj:"社區業績",
sfjd:"是否是節點",
shi:"是",
fou:"否",
home:"首頁",
wdsq:"我的社區",
jdjl:"節點獎勵",
jxjl:"競選獎勵",

jl:"獎勵",
sl:"數量",
swjryy:"尚未加入預約 無法分享好友",
wdtjdz:"我的推薦地址",
qqdd:"請求錢包交互",
dkndqb:"請打開您的錢包並在交易活動中確認以繼續您的訂單",
zzclsw:"ARC正在從事區塊鏈交易，請耐心等待交易結算",
ddz:"等待中",
dqzdbmz:"當前地址不滿足邀請條件！",
bklq:"不可領取！",

dappname:"達聯寶",
wdzc:"我的資產",
cz:"充值",
czjl:"充值記錄",
tx:"提現",
txjl:"提現記錄",
hdqy:"活動區域",
djkpsp:"當局卡牌碎片：",
zhang:"張",
dqxhz:"當區銷燬值",
ljjcze:"累計獎池總額",
qyfhze:"權益分紅總額",
jrqy:"進入區域",
czqr:"充值確認",
srsl:"請輸入數量",
txqr:"提現確認",
czcg:"充值成功",
czsb:"充值失敗",
txzwkf:"提現暫未開放",
txcg:"提現成功",
txsb:"提現失敗",
yxxq:"遊戲詳情",
jryx:"重繪遊戲",
ljcy:"立即參與",
lun:"輪",
dqrs:"當前人數：",
jxz:"進行中",
yjs:"已結束",
cyyx:"參與遊戲",
wdye:"我的餘額",
cycg:"參與成功",
xh:"序號",
dz:"地址",
hd:"獲得",
bl:"比例",
qykp:"權益卡牌",
kpsl:"卡牌總量",
yxkp:"有效卡牌",
wxkp:"無效卡牌",
qyjlljff:"權益獎勵累計發放",
zcmx:"資產明細",
sr:"收入",
zc:"支出",
lx:"類型",
yxzj:"遊戲中獎",
qyfh:"權益分紅",
bz:"備註",
di:"第",
gglb:"公告列表",
ggxq:"公告詳情",
bh:"駁回",
dsh:"待審覈",
qrz:"確認中",
stjsz:"生態建設值",
wdkpsp:"我的卡牌碎片",
grdhsl:"個人兌換NFT數量",
srsls:"請輸入兌換的NFT數量",
dh:"兌換",
dhnft:"兌換NFT",
kpmx:"碎片明細",
nfttips:"提示：每1332張卡牌碎片可兌換一張NFT",
bzjhd:"不中獎獲得",
qwkpsp:"全網卡牌碎片",
cyjl:"參與記錄",
zj:"中獎",
wzj:"未中獎",
wkj:"未開獎",
yxxh:"遊戲消耗",
qwxhzz:"全網銷毀總值",
qwstjszz:"全網生態建設總值",
zwkf:"溫馨提示：暫未開放！",
nftdh:"NFT兌換",
kpbz:"提示：碎片餘額不足",
dhcg:"兌換成功",
dhsb:"兌換失敗",
qwdhkpxh:"全網兌換卡牌消耗",
cysj:"參與時間：",
wxtsdhjs:"溫馨提示：兌換已結束！",
spye:"碎片餘額",
  ylq:"已領取",
  srlqsl:"請輸入領取數量",
  yebz:"餘額不足"


  
  
  
  














}