export const m = 
{ 
  lqlj: "确定领取",
  sqinfo: "要使用您个人账户的功能，请使用钱包授权",
  sqbtn: "钱包授权",
  sqload: "授权中...",
  dltt: "登录到您的账户",
  dlinfo: "要使用您个人账户的功能，请点击链接钱包",
  dlbtn: "链接钱包",
  bdtt: "绑定上级",
  bdinfo: "请输入推荐人钱包地址",
  bdbtn: "确认绑定",
  bdload: "绑定中...",
  lqsg: "领取成功",
  lqsb: "领取失败",
  day: "天",
  shi:"时",
  fen:"分",
  miao:"秒",
  fzcg:"复制成功",
  fzsb:"复制失败",
  yhdj:"用户等级",

  notice:"ARC是全球首个基于智算集成服务网络建设的Web3智算金融平台，还是全球首个实现无干扰、自动化运行的金融服务平台。",
  cyarc:"参与ARC",
  ljyy:"立即预约",
  yysl:"预约数量",
  xzsl:"选择数量",
  qr:"确认",
  wdldx:"我的流动性",
  wdlp:"我的LP值",
  wdqydd:"我的契约订单",
  jrgtxq:"加入ARC，共同掀起DeFi新浪潮!",  
  qyc:"契约池",
  cbdz:"持币地址",
  wdqy:"我的契约",
  qysy:"契约收益",
  qrlq:"确认领取",
  lq:"领取",
  mx:"明细",
  dqjl:"社区奖励",
  ny:"您有",
  wcqbqr:"完成钱包确认",
  zyyyyd:"注意：24小时内只能预约一笔订单",
  dqsl:"授权数量",
  yycg:"预约成功",
  yysb:"预约失败",
  mysj:"没有数据",
  sqjd:"社区节点",
  gsjd:"共识节点",
  dqjx:"社区竞选",
  gsjdzsl:"共识节点总数量",
  gsjdydcsl:"共识节点已达成数量",
  dqgsjdjc:"当前共识节点奖池",
  wdjdjc:"我的节点奖池",
  wdcjd:"未达成节点",
  ydcjd:"已达成节点",
  djzlmm:"节点总量51个，当小区业绩达到2w美金按照顺序成为节点。",
  jdmx:"节点明细",
  sqjxjc:"社区竞选奖池",
  wdsqjxjc:"我的社区竞选奖池",
  xqyjmm:"小区业绩达到1.5万美金，排名前51名可获得竞选池奖励。",
  sqpm:"社区排名",
  pm:"排名",
  xqyj:"小区业绩",
  zyj:"总业绩",
  dq:"社区",
  wdyqlj:"我的邀请链接",
  fz:"复制",
  qysqtj:"契约社区统计",
  zjtjhy:"直接推荐会员",
  xqyjzje:"小区业绩总金额",
  sqyjzje:"社区业绩总金额",
  wdfxmx:"我的分享明细",
  wx:"无效",
  wdyydd:"我的预约订单",
  ywcdqydd:"已完成的契约订单",
  dzf:"待支付",
  yyz:"预约中",
  dyz:"待质押",
  qyje:"契约金额",
  yydjs:"预约倒计时",
  zydjs:"质押倒计时",
  zfdjs:"支付倒计时",
  wcqydd:"完成契约订单",
  ywc:"已完成",
  ysh:"已赎回",
  zysl:"质押数量",
  jydjs:"解押倒计时",
  shqydd:"赎回契约订单",
  wcqyddxyzf:"完成预约订单需要支付",
  shcg:"赎回成功",
  shsb:"赎回失败",
  zfcg:"支付成功",
  zfsb:"支付失败",
  wdfx:"我的分享",
  yxyh:"有效用户",
  wxyh:"无效用户",
  grtr:"个人投入",
  sqyj:"社区业绩",
  sfjd:"是否是节点",
  shi:"是",
  fou:"否",
  home:"首页",
  wdsq:"我的社区",
  jdjl:"节点奖励",
  jxjl:"竞选奖励",

  jl:"奖励",
  sl:"数量",
  swjryy:"尚未加入预约 无法分享好友",
  wdtjdz:"我的推荐地址",
  qqdd:"请求钱包交互",
  dkndqb:"请打开您的钱包并在交易活动中确认以继续您的订单",
  zzclsw:"ARC正在从事区块链交易，请耐心等待交易结算",
  ddz:"等待中",
  dqzdbmz:"当前地址不满足邀请条件！",
  bklq:"不可领取！",

  dappname:"达联宝",
  wdzc:"我的资产",
  cz:"充值",
  czjl:"充值记录",
  tx:"提现",
  txjl:"提现记录",
  hdqy:"活动区域",
  djkpsp:"当局卡牌碎片：",
  zhang:"张",
  dqxhz:"当区销毁值",
  ljjcze:"累计奖池总额",
  qyfhze:"权益分红总额",
  jrqy:"进入区域",
  czqr:"充值确认",
  srsl:"请输入数量",
  txqr:"提现确认",
  czcg:"充值成功",
  czsb:"充值失败",
  txzwkf:"提现暂未开放",
  txcg:"提现成功",
  txsb:"提现失败",
  yxxq:"游戏详情",
  jryx:"刷新游戏",
  ljcy:"立即参与",
  lun:"轮",
  dqrs:"当前人数：",
  jxz:"进行中",
  yjs:"已结束",
  cyyx:"参与游戏",
  wdye:"我的余额",
  cycg:"参与成功",
  xh:"序号",
  dz:"地址",
  hd:"获得",
  bl:"比例",
  qykp:"权益卡牌",
  kpsl:"卡牌总量",
  yxkp:"有效卡牌",
  wxkp:"无效卡牌",
  qyjlljff:"权益奖励累计发放",
  zcmx:"资产明细",
  sr:"收入",
  zc:"支出",
  lx:"类型",
  yxzj:"游戏中奖",
  qyfh:"权益分红",
  bz:"备注",
  di:"第",
  gglb:"公告列表",
  ggxq:"公告详情",
  bh:"驳回",
  dsh:"待审核",
  qrz:"确认中",
  stjsz:"生态建设值",
  wdkpsp:"我的卡牌碎片",
  grdhsl:"个人兑换NFT数量",
  srsls:"请输入兑换的NFT数量",
  dh:"兑换",
  dhnft:"兑换NFT",
  kpmx:"碎片明细",  
  nfttips:"提示：每1332张卡牌碎片可兑换一张NFT",
  bzjhd:" 不中奖获得",
  qwkpsp:"全网卡牌碎片",
  cyjl:"参与记录",
  zj:"中奖",
  wzj:"未中奖",
  wkj:"待开奖",
  yxxh:"游戏消耗",
  qwxhzz:"全网销毁总值",
  qwstjszz:"全网生态建设总值",
  zwkf:"温馨提示：暂未开放！",
  nftdh:"NFT兑换",
  kpbz:"提示：碎片余额不足",
  dhcg:"兑换成功",
  dhsb:"兑换失败",
  qwdhkpxh:"全网兑换卡牌消耗",
  cysj:"参与时间：",
  wxtsdhjs:"温馨提示：兑换已结束！",
  spye:"碎片余额",
  ylq:"已领取",
  srlqsl:"请输入领取数量",
  yebz:"余额不足"


















}