export const m = {
  lqlj: "Confirm claim ",
  sqinfo: "To use the features of your personal account, please use Wallet authorization ",
  sqbtn: "Wallet Authorization ",
  sqload: "Authorizing..." ,
  dltt: "Sign in to your account ",
  dlinfo: "To use the features of your personal account, click on the Link Wallet ",
  dlbtn: "Linked Wallet ",
  bdtt: "Bind parent ",
  bdinfo: "Please enter the referrer's wallet address ",
  bdbtn: "Confirm binding ",
  bdload: "binding..." ,
  lqsg: "Claim successful ",
  lqsb: "Claim Failure ",
  day : "day ",
  shi:" Hour ",
  fen: "Fen!",
  miao:" Seconds ",
  fzcg:" Copy success ",
  fzsb:" Replication failure ",
  yhdj:" User Level ",
  
  notice:"DA is the world's first Web3 intelligent financial platform based on intelligent integrated service network construction, and the world's first financial service platform to achieve uninterrupted and automated operation." ,
  cyarc:" Participate in DA",
  ljyy:" Make an appointment now ",
  yysl:" Number of appointments ",
  xzsl:" Select quantity ",
  qr:" Confirm ",
  wdldx:" My Liquidity ",
  wdlp:" My LP value ",
  wdqydd:" My Contract Order ",
  jrgtxq:" Join DA for a new wave of DeFi!" ,
  qyc:" Contract Pool ",
  cbdz:" Holding address ",
  wdqy:" My Contract ",
  qysy:" Contract income ",
  qrlq:" Confirm collection ",
  lq:" Claim ",
  mx:" Details ",
  dqjl:" Community Rewards ",
  ny:" You have ",
  wcqbqr:" Complete wallet confirmation ",
  zyyyyd:" Note: Only one order can be booked within 24 hours ",
  dqsl:" Authorized Quantity ",
  yycg:" Reservation successful ",
  yysb:" Appointment failed",
  mysj:" No data ",
  sqjd:" Community node ",
  gsjd: "Consensus Node",
  dqjx:" Community Campaigning ",
  gsjdzsl: "Total number of Consensus nodes",
  gsjdydcsl:" Number of consensus nodes reached ",
  dqgsjdjc:" Current Consensus Node Award Pool ",
  wdjdjc:" My Node Award Pool ",
  wdcjd:" Node not reached ",
  ydcjd:" Node reached",
  djzlmm:" The total number of nodes is 51, and when the performance of the community reaches $2w, the nodes will become in order." ,
  jdmx:" Node Details",
  sqjxjc:" Community Campaign Award Pool ",
  wdsqjxjc:" My Community Campaign Award Pool ",
  xqyjmm:" Community performance of 15,000 US dollars, the top 51 will be awarded the campaign pool." ,
  sqpm:" Community Ranking ",
  pm:" Ranking ",
  xqyj:" Community Performance ",
  zyj:" Total Performance ",
  dq:" Community ",
  wdyqlj:" My invitation link ",
  fz:" Copy ",
  qysqtj:" Contract Community Statistics ",
  zjtjhy:" Direct referral members ",
  xqyjzje:" Total amount of community performance ",
  sqyjzje:" Total amount of community performance ",
  wdfxmx:" My Share Details ",
  wx:" Invalid ",
  wdyydd:" My Reservation Order ",
  ywcdqydd:" Completed Contract Order ",
  dzf:" to be paid ",
  yyz:" Pending appointment ",
  dyz:" To be pledged ",
  qyje:" Contract amount ",
  yydjs:" Appointment Countdown ",
  zydjs:" Pledge Countdown ",
  zfdjs:"Pay Countdown",
  wcqydd:" Complete Contract Order ",
  ywc:" Completed ",
  ysh:" Redeemed ",
  zysl:" Quantity pledged ",
  jydjs:" Release Countdown ",
  shqydd:" Redemption Contract Order ",
  wcqyddxyzf:" Payment required to complete reservation order ",
  shcg:" Redemption successful ",
  shsb:" Redemption failed ",
  zfcg:" Payment success ",
  zfsb:" Payment failure ",
  wdfx:" My Share ",
  yxyh:" Valid User ",
  wxyh:" Invalid User ",
  grtr:" Personal Input ",
  sqyj:" Community Performance ",
  sfjd:" Node or not ",
  shi:" Yes ",
  fou:" No ",
  home:" Home ",
  wdsq:" My Community",
  jdjl:" Node Reward ",
  jxjl:" Campaign Rewards",
  tx:" Cash withdrawal ",
  jl:" Reward ",
  sl:" Quantity",
  swjryy:"Not yet joined the appointment, unable to share friends",
  wdtjdz:"My recommended address",
  qqdd: "Request wallet interaction",
  dkndqb: "Please open your wallet and confirm in the transaction activity to proceed with your order",
  zzclsw: "DA is engaged in blockchain transactions, please be patient and wait for transaction settlement",
  ddz: "Waiting",
  dqzdbmz:"The current address does not meet the invitation criteria! ",
  bklq:"Not available for collection！",


  dappname:" Dalianbao ",
wdzc:" My Assets ",
cz:" Top up ",
czjl:" Recharge record ",
tx:" Cash withdrawal ",
txjl:" Withdrawal Record ",
hdqy:" Active Area ",
djkpsp:" Card fragment:",
zhang:" Piece",
dqxhz:" When zone destruction value ",
ljjcze:" Aggregate Prize Pool ",
qyfhze:" Total equity dividend ",
jrqy:" Enter the zone ",
czqr:" Recharge confirmation ",
srsl:" Please enter quantity ",
txqr:" Withdrawal Confirmation ",
czcg:" Top up successful ",
czsb:" Recharge failure ",
txzwkf:" Withdrawal is not open ",
txcg:" Successful Withdrawal ",
txsb:" Withdrawal Failure ",
yxxq:" Game Details ",
jryx:" Refresh game ",
ljcy:" Get Involved Now ",
lun:" wheel ",
dqrs:" Current population :",
jxz:" In progress ",
yjs:" It's over ",
cyyx:" Play the Game ",
wdye:" My balance ",
cycg:" Participate in Success ",
xh:" Number",
dz:" Address ",
hd:" Get ",
bl:" Proportion ",
qykp:" Equity Card ",
kpsl:" Total Number of Cards ",
yxkp:" Valid Cards ",
wxkp:" Invalid Card ",
qyjlljff:" Equity Awards are distributed in aggregate ",
zcmx:" Asset Details ",
sr:" Income ",
zc:" Expenditure ",
lx:" Type ",
yxzj:" Winning the game ",
qyfh:" Equity dividend ",
bz:" Remarks ",
di:" first ",
gglb:" Announcements ",
ggxq:" Announcement Details",
bh:"Turn down",
dsh:"To be reviewed",
qrz:"Confirming",
stjsz:" Ecological construction Value ",
wdkpsp:" My Card Fragments ",
grdhsl:" Individual Exchange NFT Quantity ",
srsls:" Please enter the number of NFT redeemed ",
dh:" Exchange ",
dhnft:" Exchange for NFT",
kpmx:"Fragment detail",
nfttips:" Tip: One NFT for every 1332 card pieces",
bzjhd:" Don't win",
qwkpsp:"All network card fragments",
cyjl:"Game record",
zj:"Winning the lottery",
wzj:"Not winning",
wkj:"Undrawn draw",
yxxh:"Game consumption",
qwxhzz:"Total value of destruction",
qwstjszz:"Total value of ecological",
zwkf:"Kind reminder: Not yet open!",
nftdh:"NFT Exchange ",
kpbz:"Tip: The debris balance is insufficient",
dhcg:"Successful exchange ",
dhsb:"Conversion Failure",
qwdhkpxh:"Card redemption consumption across the entire network",
cysj:"Participation time：",
wxtsdhjs:"Tips: The exchange is over!",
spye:"Balance of fragments",
ylq:"Received",
srlqsl:"Please enter the quantity to be received",
yebz:"Insufficient balance"
}



